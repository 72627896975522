import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { useField, useForm } from 'react-final-form';
import { RadioScreenTemplate } from '../../../components/ScreenTemplates/RadioScreenTemplate';
import { WithdrawalType, YesNoQuestion, makeChoices } from '../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Driver, Event } from '../../../fsm/types';
import { DateFromYearsAgo } from '../../../utils/date';
import type { DriverScreenProps } from '../../types/DriverScreenProps';

interface CancellationOrSuspensionRecordScreenProps extends DriverScreenProps {
  anotherOneRecord?: boolean; // https://youtu.be/w-6MDQZ9cZc
}

const buildChoices = (anotherOneRecord: boolean) =>
  makeChoices({ ...WithdrawalType, NO: YesNoQuestion.NO }, [
    [YesNoQuestion.NO, 'Non'],
    [WithdrawalType.Suspension, `Oui, ${anotherOneRecord ? 'une suspension' : 'suspendu'}`],
    [WithdrawalType.Cancellation, `Oui, ${anotherOneRecord ? 'une annulation' : 'annulé'}`],
  ]);

export function CancellationOrSuspensionRecordScreen({
  driver,
  anotherOneRecord: anotherOne,
}: CancellationOrSuspensionRecordScreenProps): ReactNode {
  const requiredValidator = useRequiredValidator();
  const send = useSubscriptionFsmDispatch();
  const driverKey: `${Driver}Driver` = `${driver}Driver`;

  const {
    context: {
      editingInsuranceRecord,
      answers: { [driverKey]: driverAnswers },
    },
  } = useSubscriptionFsmState();

  const hasAlreadyDeclaredCancellationOrSuspensionRecords = Boolean(
    anotherOne ||
      driverAnswers?.terminationRecords?.some((termination) => Boolean(termination.cancellationOrSuspensionRecord)),
  );

  const choices = buildChoices(hasAlreadyDeclaredCancellationOrSuspensionRecords);

  const hasBeenCancelledOrSuspendedFieldName = `${driver}Driver.hasBeenCancelledOrSuspended`;
  const cancellationOrSuspensionRecordsFieldName = `${driverKey}.cancellationOrSuspensionRecords[${
    anotherOne ? editingInsuranceRecord.index + 1 : editingInsuranceRecord.index
  }].withdrawalType`;

  const mainFieldName = hasAlreadyDeclaredCancellationOrSuspensionRecords
    ? cancellationOrSuspensionRecordsFieldName
    : hasBeenCancelledOrSuspendedFieldName;

  const secondaryFieldName = hasAlreadyDeclaredCancellationOrSuspensionRecords
    ? hasBeenCancelledOrSuspendedFieldName
    : cancellationOrSuspensionRecordsFieldName;

  const {
    input: { value },
  } = useField<WithdrawalType | YesNoQuestion.NO>(mainFieldName);

  const { submit, change } = useForm();

  const fiveYearsAgo = DateFromYearsAgo(5);
  const threeYearsAgo = DateFromYearsAgo(3);
  const title = hasAlreadyDeclaredCancellationOrSuspensionRecords
    ? `Merci pour ces informations. ${
        driver === Driver.Primary ? 'Avez-vous' : 'A-t-il'
      } eu d'autres suspensions ou annulations de permis\u00A0?`
    : // @TODO : Remove the "new Date() >= new Date(2024, 9, 1)" condition when reaching the 1st October 2024
      `${driver === Driver.Primary ? 'Votre' : 'Son'} permis de conduire a-t-il été suspendu ou annulé depuis le ${
        new Date() >= new Date(2024, 9, 1) ? threeYearsAgo : fiveYearsAgo
      }\u00A0?`;

  return (
    <RadioScreenTemplate
      title={title}
      fieldName={mainFieldName}
      defaultValue={driverAnswers?.hasBeenCancelledOrSuspended === YesNoQuestion.NO ? YesNoQuestion.NO : undefined}
      choices={choices}
      validate={requiredValidator}
      onSubmit={() => {
        if (hasAlreadyDeclaredCancellationOrSuspensionRecords && value === YesNoQuestion.NO) {
          send(Event.SKIP);
          return;
        }

        change(secondaryFieldName, value);

        submit();
      }}
      {...(!hasAlreadyDeclaredCancellationOrSuspensionRecords
        ? {
            highlightTitle: 'Quelle est la différence entre suspension et annulation\u00A0?',
            highlightContent: (
              <ul>
                <li>
                  {driver === Driver.Primary
                    ? 'Si vous avez dû repasser votre permis'
                    : "S'il a dû repasser son permis"}
                  , il s’agit d’une <b>annulation</b>.
                </li>
                <li>
                  Si ce n’est pas le cas, il s’agit d’une <b>suspension</b>.
                </li>
              </ul>
            ),
          }
        : undefined)}
    />
  );
}
