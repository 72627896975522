import { PlusRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Button, VStack, View } from '@ornikar/kitt-universal';
import { type ReactNode } from 'react';
import type { CancellationOrSuspensionRecord, ClaimRecord } from '../../../../../fsm/answers';
import { InsuranceRecordType } from '../../../../../fsm/answers';
import { useSubscriptionFsmDispatch } from '../../../../../fsm/context';
import { Event } from '../../../../../fsm/types';
import { DateFromYearsAgo } from '../../../../../utils/date';
import { generateSectionTitle } from '../../helpers';
import { CancellationOrSuspensionCard } from '../CancellationOrSuspensionCard';
import { ClaimCard } from '../ClaimCard';
import { SectionTitle } from '../SectionTitle';

export interface RecordsSectionProps {
  recordType: InsuranceRecordType;
  records: (CancellationOrSuspensionRecord | ClaimRecord)[] | undefined;
}

export function RecordsSection({ recordType, records }: RecordsSectionProps): ReactNode {
  const send = useSubscriptionFsmDispatch();

  const recordCount = records?.length || 0;
  const title = generateSectionTitle(recordType, recordCount);
  // @TODO : Remove the "&& new Date() < new Date(2024, 9, 1)" when reaching the 1st October 2024
  const fromDate = DateFromYearsAgo(
    recordType === InsuranceRecordType.CancellationOrSuspension && new Date() < new Date(2024, 9, 1) ? 5 : 3,
  );

  const handleEdit = (index: number): void => {
    send(Event.EDIT_INSURANCE_RECORD, {
      recordType,
      recordIndex: index,
    });
  };

  const handleDelete = (index: number): void => {
    send(Event.DELETE_INSURANCE_RECORD, {
      recordType,
      recordIndex: index,
    });
  };

  const recordsContent =
    recordType === InsuranceRecordType.Claim
      ? records?.map((record, index) => (
          <ClaimCard
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            claim={record as ClaimRecord}
            onEdit={() => handleEdit(index)}
            onDelete={() => handleDelete(index)}
          />
        ))
      : records?.map((record, index) => (
          <CancellationOrSuspensionCard
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            cancellationOrSuspension={record as Required<CancellationOrSuspensionRecord>}
            onEdit={() => handleEdit(index)}
            onDelete={() => handleDelete(index)}
          />
        ));

  return (
    <VStack space="kitt.4">
      <SectionTitle title={title} fromDate={fromDate} />
      {recordCount > 0 && <VStack space="kitt.4">{recordsContent}</VStack>}
      <View alignSelf={{ base: 'stretch', large: 'flex-end' }}>
        <Button
          stretch={{ base: true, large: false }}
          icon={<PlusRegularIcon />}
          type="default"
          onPress={() => send(Event.START_ADDING_INSURANCE_RECORD)}
        >
          Ajouter
        </Button>
      </View>
      {recordCount === 0 && <View borderBottomWidth="1px" borderBottomColor="kitt.palettes.lateOcean.black100" />}
    </VStack>
  );
}
